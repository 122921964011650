<div class="team">
  <div class="head">
    <h1>Team-Termine</h1>
  </div>

  <div class="list" *ngIf="league">
    <h3>DSAB Spieltermine der Liga</h3>
    <div class="filter" *ngIf="message && teams.length === 0">
      {{message}}
    </div>
    <div class="filter" *ngIf="teams.length > 0">
      <img src="assets/img/filter.svg" alt="Filter Teams">
      <select id="filter_team" (change)="currentFilter = $event.target.value; filterDates()">
        <option value="" selected disabled>Team wählen</option>
        <option *ngFor="let team of teams" [selected]="currentFilter === team" value="{{team}}">{{team}}</option>
      </select>
    </div>
    <ul>
      <li *ngFor="let game of dates">
        <div><div>{{game.homeTeam}}</div><div>vs.</div><div>{{game.guestTeam}}</div></div>
        <div>{{game.day}} - {{game.date}} - {{game.time}} Uhr</div>
        <div>
          Ergebniss:<br>
          {{game.results.points}} Punkte - {{game.results.games}} Spiele - {{game.results.sets}} Legs</div>
      </li>
    </ul>
  </div>
</div>

<app-footer></app-footer>
