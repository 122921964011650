import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UserService} from '@app/_services';
import {RanglisteService} from '@app/_services/rangliste.service';
import {first} from 'rxjs/operators';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-dsab-table',
  templateUrl: './dsab-table.component.html',
  styleUrls: ['./dsab-table.component.less']
})
export class DsabTableComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() change;
  leagueId: string;
  seasonId: string;
  loading = true;
  league;
  tableHtml: SafeHtml;
  userTeam = '';

  constructor(
    private userService: UserService,
    private dsab: RanglisteService,
    private sanitized: DomSanitizer
  ){

  }

  ngOnChanges(changes) {
    if (this.settings && this.settings.hasOwnProperty('league') && this.settings.hasOwnProperty('season')) {
      this.leagueId = this.settings.league;
      this.seasonId = this.settings.season;

      this.dsab.getLeague(this.seasonId, this.leagueId)
        .pipe(first())
        .subscribe(league => {
          this.loading = false;
          this.league = league[0];
          this.createHtmlString();
        }, error => {
          this.loading = false;
        })
    }
  }

  ngOnInit() {
    if (window.hasOwnProperty('settings')) {
      // @ts-ignore
      this.userTeam = window.settings.team;
    }

  }

  createHtmlString( ) {
    let html = '';
    for (const team of this.league.results) {
      let cssClass = '';
      if (team.team === this.userTeam) cssClass = 'current';
      html += `<tr class="${cssClass}">`;
      html += `  <td>${team.place}</td>
      <td>${(!team.team) ? '' : team.team}</td>
      <td>${team.matches}</td>
      <td>${team.points}</td>
      <td>${team.games.won}:${team.games.lost}</td>
      <td>${team.legs.won}:${team.legs.lost}</td>
      </tr>
      `;
    }
    this.tableHtml = this.sanitized.bypassSecurityTrustHtml(html);
  }

}
