<div class="login">
  <div class="container">

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <img src="./assets/img/logo_default.png" alt="Dart Plan">
      <div class="form-group">
        <img src="assets/img/mail.svg" alt="E-Mail">
        <input placeholder="E-Mail" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Bitte gib deine E-Mail Adresse ein.</div>
        </div>
      </div>
      <div class="form-group">
        <img class="pass" src="assets/img/passwort.svg" alt="Passwort">
        <input placeholder="Passwort" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Bitte gib ein Passwort ein.</div>
        </div>
      </div>
      <div class="controls">
        <div>
        </div>
        <div class="forgot">
          <a routerLink="/forgot-password">Passwort vergessen</a>
        </div>
      </div>
      <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Login
      </button>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      <div class="create">
        Du hast noch keinen Account? <a routerLink="/">Erstelle dir einen!</a>
      </div>
    </form>
  </div>
</div>
