import {Component, OnInit} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import {AuthenticationService, UserService} from '@app/_services';
import {first} from 'rxjs/operators';
import {NotificationService} from '@app/notification.service';
import {RanglisteService} from '@app/_services/rangliste.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {
  form;
  loading = false;
  areas = [];
  groups = [];
  seasons = [];
  leagues = [];
  teams = [];
  refresh = '1233';
  settings = {
    user_id: '',
    area: '',
    group: '',
    groupName: '',
    season: '',
    seasonName: '',
    league: '',
    leagueName: '',
    team: ''
  }

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private ranglisteService: RanglisteService,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.settings.user_id = authenticationService.currentUserValue.id;
    this.form = this.formBuilder.group({
      teamName: ['', [Validators.required, Validators.minLength(3)]],
      logo: [''],
      logoSource: [null],
      background: [''],
      backgroundSource: [null]
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Einstellungen - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Eionstellungen für deinen Darts Training Manager.'
    }, "name='description'");

    this.userService.getSettings()
      .pipe(first())
      .subscribe(settings => {
        if (settings && settings.length > 0) {
          this.settings = settings[0];
        }
      })

    this.areas = this.ranglisteService.getAreas();


  }

  saveSettings() {
    this.userService.saveSettings(this.settings)
      .pipe(first())
      .subscribe(settings => {
        // @ts-ignore
        window.settings = settings;
        this.refresh = btoa(Math.random().toString()).substring(10, 15);
        this.notificationService.success('Einstellungen gespeichert.')
      }, error => {
        this.notificationService.error('Fehler beim speichern der Einstellungen.')
      })
  }

  getAreaGroups( e ) {
    this.loading = true;
    if (e.target.value !== '') {
      this.settings.area = e.target.value;
      this.ranglisteService.getGroupsByArea(e.target.value)
        .pipe(first())
        .subscribe(groups => {
          if (groups.length > 0) {
            this.groups = groups;
          } else {
            this.notificationService.error('Keine Gruppen in diesem Bundesland gefunden.');
          }
          this.loading = false;
        }, error => {
          this.loading = false;
          this.notificationService.error('Es ist ein Fehler aufgetreten, bitte versuche es später erneut.')
        })
    }
  }

  getSeasons( e ) {
    this.loading = true;
    if (e.target.value !== '') {
      this.ranglisteService.getSeasons(e.target.value)
        .pipe(first())
        .subscribe(seasons => {
          if (seasons.length > 0) {
            this.seasons = seasons;
            this.settings.season = seasons[0].seasonId;
            this.settings.seasonName = seasons[0].seasonName;
            this.settings.group = e.target.value;
            const currentGroup = this.groups.filter(obj => obj.leagueGroupId == e.target.value);
            this.settings.groupName = currentGroup[0].leagueGroup;
            this.getLeagues(this.settings.season);
          } else {
            this.notificationService.error('Keine Saisons in dieser Gruppe gefunden.');
          }
          this.loading = false;
        }, error => {
          this.loading = false;
          this.notificationService.error('Es ist ein Fehler aufgetreten, bitte versuche es später erneut.')
        })
    }
  }

  getLeagues( e ) {
    this.loading = true;
    let id = 0;
    if (typeof e === 'number') {
      id = e;
    } else {
      id = e.target.value;
    }

    if (id !== 0) {
      this.ranglisteService.getLeagues(id.toString())
        .pipe(first())
        .subscribe(leagues => {
          this.settings.season = id.toString();
          const currentSeason = this.seasons.filter(obj => obj.seasonId === id);
          this.settings.seasonName = currentSeason[0].seasonName;
          if (leagues.length > 0) {
            this.leagues = leagues;
          } else {
            this.notificationService.error('Keine Liga in dieser Saison gefunden.');
          }
          this.loading = false;
        }, error => {
          this.loading = false;
          this.notificationService.error('Es ist ein Fehler aufgetreten, bitte versuche es später erneut.')
        })
    }
  }

  getTeams(league) {
    this.teams = [];
    for (const game of league.dates) {
      if (!this.teams.includes(game.homeTeam)) {
        this.teams.push(game.homeTeam);
      }
      if (!this.teams.includes(game.guestTeam)) {
        this.teams.push(game.guestTeam);
      }
    }
  }

  saveTeam(event) {
    this.settings.team = event.target.value;
    // @ts-ignore
    window.settings.team = this.settings.team;
    this.saveSettings();
  }

  selectedLeague( e ) {
    this.settings.league = e.target.value;
    const currentLeague = this.leagues.filter(obj => obj.leagueId == e.target.value);
    this.settings.leagueName = currentLeague[0].leagueName;
    this.getTeams(currentLeague[0]);
    this.saveSettings();
  }

}
