<app-notification></app-notification>
<!-- nav -->
<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
    <a class="nav-item nav-link" routerLink="/" (click)="openMenu = false">
      <img src="./assets/img/logo_default_long.png" alt="Dart Plan">
    </a>
    <svg (click)="openMenu = !openMenu" xmlns="http://www.w3.org/2000/svg" width="27" height="18" viewBox="0 0 27 18">
      <g id="Gruppe_76" data-name="Gruppe 76" transform="translate(-36 -20)">
        <line id="Linie_13" data-name="Linie 13" x2="27" transform="translate(36 37)" fill="none" stroke="#191919" stroke-width="2"/>
        <line id="Linie_12" data-name="Linie 12" x2="27" transform="translate(36 29)" fill="none" stroke="#191919" stroke-width="2"/>
        <line id="Linie_11" data-name="Linie 11" x2="27" transform="translate(36 21)" fill="none" stroke="#191919" stroke-width="2"/>
      </g>
    </svg>
</nav>

<div class="slide-in" [ngClass]="{active: openMenu === true}" *ngIf="currentUser">
  <svg (click)="openMenu = !openMenu" id="close" xmlns="http://www.w3.org/2000/svg" width="18.093" height="18.093" viewBox="0 0 18.093 18.093">
    <g transform="translate(-58.5 -58.5)">
      <g id="State:EnabledActivatedHighlight" transform="translate(58.5 58.5)">
        <path id="Pfad_220" data-name="Pfad 220" d="M76.593,60.518,74.58,58.5l-7.031,7.031L60.518,58.5,58.5,60.518l7.031,7.031L58.5,74.58l2.018,2.013,7.031-7.031,7.031,7.031,2.013-2.013-7.031-7.031Z" transform="translate(-58.5 -58.5)" fill="#191919"/>
      </g>
      <g id="State:EnabledActivatedNoHighlight" transform="translate(58.5 58.5)">
        <path id="Pfad_221" data-name="Pfad 221" d="M76.593,60.518,74.58,58.5l-7.031,7.031L60.518,58.5,58.5,60.518l7.031,7.031L58.5,74.58l2.018,2.013,7.031-7.031,7.031,7.031,2.013-2.013-7.031-7.031Z" transform="translate(-58.5 -58.5)" fill="#191919"/>
      </g>
    </g>
  </svg>
  <a class="nav-item nav-link" routerLink="/" (click)="openMenu = false">
    <img class="logo" src="./assets/img/logo_default.png" alt="Dart Plan">
  </a>

  <div class="navbar-nav">
    <a class="nav-item nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/" (click)="openMenu = false">
      <img src="./assets/img/home.svg" alt="Startseite">
      Home</a>
    <a class="nav-item nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/team" (click)="openMenu = false">
      <img src="./assets/img/team.svg" alt="Team Mitglieder">
      Team Mitglieder</a>
    <a class="nav-item nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/team/dates" (click)="openMenu = false">
      <img src="./assets/img/calendar.svg" alt="Team Termine">
      Team Termine</a>
    <a class="nav-item nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/training" (click)="openMenu = false">
      <img src="./assets/img/training.svg" alt="Training starten">
      Training starten</a>
    <a class="nav-item nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" routerLink="/settings" (click)="openMenu = false">
      <img src="./assets/img/stats.svg" alt="Einstellungen">
      Einstellungen</a>
    <a class="nav-item nav-link" (click)="logout()">
      <img src="./assets/img/logout.svg" alt="Ausloggen">
      Logout</a>
  </div>
</div>

<!-- main app container -->
<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet></router-outlet>
</div>

