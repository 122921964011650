<div class="login">
  <div class="container">

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <img src="./assets/img/logo_default.png" alt="Dart Plan">
      <p>Du hast eine Email bekommen, bitte gib hier den enthaltenen Token ein.</p>
      <div class="form-group">
        <img class="pass" src="assets/img/token.svg" alt="Token">
        <input placeholder="Token" type="text" formControlName="token" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.token.errors }" />
        <div *ngIf="submitted && f.token.errors" class="invalid-feedback">
          <div *ngIf="f.token.errors.required">Bitte gib einen Token ein.</div>
        </div>
      </div>
      <div class="form-group">
        <img class="pass" src="assets/img/passwort.svg" alt="Passwort">
        <input placeholder="Neues Passwort" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Bitte gib ein neues Passwort ein.</div>
          <div *ngIf="f.password.errors.minlength">Dein Passwort muss mindestens 6 Zeichen lang sein.</div>
        </div>
      </div>
      <div class="form-group">
        <img class="pass" src="assets/img/passwort.svg" alt="Passwort">
        <input placeholder="Passwort wiederholen" type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">Bitte gib ein neues Passwort ein.</div>
        </div>
      </div>
      <button [disabled]="loading" class="btn btn-primary" style="margin-top: 20px">
        <img *ngIf="loading" src="assets/img/loading.svg" alt="loading">
        Passwort zurücksetzen
      </button>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      <p class="back"><a routerLink="/">Zurück zum Login</a> </p>
    </form>
  </div>
</div>
