import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import {User} from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class RanglisteService {

  constructor(private http: HttpClient) { }

  getAreas() {
    return [
      'Baden-Württemberg',
      'Bayern',
      'Berlin',
      'Brandenburg',
      'Bremen',
      'Hamburg',
      'Hessen',
      'Mecklenburg-Vorpommern',
      'Niedersachsen',
      'Nordrhein-Westfalen',
      'Rheinland-Pfalz',
      'Saarland',
      'Sachsen',
      'Sachsen-Anhalt',
      'Schleswig-Holstein',
      'Thueringen',
    ];
  }

  getGroupsByArea( area: string) {
    return this.http.get<any>(`${environment.ranglisteUrl}/get-league-by-area?area=${area}`);
  }

  getSeasons( group: string ) {
    return this.http.get<any>(`${environment.ranglisteUrl}/get-seasons?id=${group}`);
  }

  getLeagues( seasonId: string ) {
    return this.http.get<any>(`${environment.ranglisteUrl}/get-leagues?id=${seasonId}`);
  }

  getLeague( seasonId: string, leagueId: string) {
    return this.http.get<any>(`${environment.ranglisteUrl}/get-league?id=${leagueId}&seasonId=${seasonId}`)
  }
}
