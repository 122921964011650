import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@app/_services';
import {Meta, Title} from '@angular/platform-browser';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.less']
})
export class PasswordForgotComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle('Passwort zurücksetzen - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Setze dein Passwort zurück'
    }, "name='description'");

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.loginForm.value.email)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/forgot-password/token']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
