import {Component, OnInit} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import {UserService} from '@app/_services';
import {first} from 'rxjs/operators';
import {NotificationService} from '@app/notification.service';

@Component({
  selector: 'app-add-player',
  templateUrl: './add-player.component.html',
  styleUrls: ['./add-player.component.less']
})
export class AddPlayerComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() addedUser = new EventEmitter<string>();
  form;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService
  ) {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      street: ['', [Validators.required, Validators.minLength(3)]],
      plz: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      dsabNumber: ['', [Validators.required, Validators.minLength(3)]],
      birthDate: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get street() {
    return this.form.get('street');
  }

  get plz() {
    return this.form.get('plz');
  }

  get city() {
    return this.form.get('city');
  }

  get dsabNumber() {
    return this.form.get('dsabNumber');
  }

  get birthDate() {
    return this.form.get('birthDate');
  }

  get phone() {
    return this.form.get('phone');
  }

  onSubmit() {
    this.userService.saveNewTeamMember(this.form.value)
      .pipe(first())
      .subscribe(member => {
        this.form.reset();
        this.addedUser.emit(btoa(Math.random().toString()).substring(10, 15));
      }, error => {
        this.notificationService.error('Es ist ein Fehler aufgetretet. ' + error, 5000);
      });
  }

  ngOnInit() {
  }

  abortNewPlayer() {
    this.form.reset();
    this.newItemEvent.emit(btoa(Math.random().toString()).substring(10, 15));
  }

}
