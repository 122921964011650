import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UserService} from '@app/_services';
import {first} from 'rxjs/operators';
import {Training} from '@app/_models/training';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() startDate: string = new Date().toISOString();
  @Input() endDate: string = new Date().toISOString();
  @Input() trainingId: string;
  @Input() showLegend = true;
  @Input() refresh: string;
  loading = true;

  sessions: Training[] = [];
  player = [];
  tableData = [];
  tableHtml: SafeHtml;
  noDate = false;

  constructor(
    private userService: UserService,
    private sanitized: DomSanitizer
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes) {
    if (this.trainingId) {
      this.userService.getSession(this.trainingId)
        .pipe(first())
        .subscribe( session => {
          this.loading = false;
          this.sessions = [session];
          this.getTeamData();
        });
    } else {
      this.userService.getSessionsByDate(this.startDate, this.endDate)
        .pipe(first())
        .subscribe(sessions => {
            this.getTeamData();
            this.sessions = sessions;
            this.loading = false;
          },
          error => {
            console.log(error);
          });
    }
  }

  getTeamData() {
    this.player = [];
    const context = this;
    this.userService.getTeam()
      .pipe(first()).subscribe(team => {
      for (const player of team) {
        this.player.push({
          firstName: player.firstName,
          lastName: player.lastName,
          id: player._id
        });
      }
      context.createTableData();
    });
  }

  getGuestPlayers() {
    if (!this.trainingId) { return; }
    for (const session of this.sessions) {
      for (const match of session.matches) {

        if (this.player.filter(e => e.id === match.home.id).length === 0) {
          this.player.push({
            firstName: match.home.firstName,
            lastName: match.home.lastName,
            id: match.home.id
          });
        }

        if (this.player.filter(e => e.id === match.guest.id).length === 0) {
          this.player.push({
            firstName: match.guest.firstName,
            lastName: match.guest.lastName,
            id: match.guest.id
          });
        }
      }
    }
  }

  createTableData() {
    this.getGuestPlayers();
    this.tableData = [];
    const results = [];

    for (const player of this.player) {
      const playerResult = {
        player: player.firstName + ' ' + player.lastName,
        player_id: player.id,
        games: 0,
        points: 0,
        legs: {
          won: 0,
          lost: 0,
          difference: 0
        }
      };

      for (const session of this.sessions) {
        // get only matches where player is involved
        const matches = session.matches.filter(obj => {
          return obj.home.id === player.id || obj.guest.id === player.id;
        });

        for (const match of matches) {
          if (!this.trainingId) {
            if (
              this.player.filter(e => e.id === match.home.id).length === 0 ||
              this.player.filter(e => e.id === match.guest.id).length === 0) {
              continue;
            }
          }

          const legs = {won: 0, lost: 0};
          playerResult.games++;

          if (match.home.id === player.id ) {
            legs.won = Number(match.home.result);
            legs.lost = Number(match.guest.result);
          } else {
            legs.won = Number(match.guest.result);
            legs.lost = Number(match.home.result);
          }

          playerResult.legs.won = playerResult.legs.won + legs.won;
          playerResult.legs.lost = playerResult.legs.lost + legs.lost;

          if (legs.won === 3 && legs.lost === 0) {
            playerResult.points = 5 + playerResult.points;
          } else  if (legs.won === 3 && legs.lost === 1) {
            playerResult.points = 4 + playerResult.points;
          } else  if (legs.won === 3 && legs.lost === 2) {
            playerResult.points = 3 + playerResult.points;
          } else  if (legs.won === 2 && legs.lost === 3) {
            playerResult.points = 2 + playerResult.points;
          } else  if (legs.won === 1 && legs.lost === 3) {
            playerResult.points = 1 + playerResult.points;
          }
        }
      }
      playerResult.legs.difference = playerResult.legs.won - playerResult.legs.lost;
      results.push(playerResult);
    }

    results.sort((a, b) => {
      if (a.points > b.points) {
        return -1;
      }
      if (a.points < b.points) {
        return 1;
      }
      return 0;
    });

    results.sort((a, b) => {
      if (a.points === b.points && a.legs.difference > b.legs.difference) {
        return -1;
      }
      if (a.points === b.points && a.legs.difference < b.legs.difference) {
        return 1;
      }
      return 0;
    });

    if (results.length === 0) {
      results.push({
          player: '',
          games: 0,
          points: 0,
          legs: {
            won: 0,
            lost: 0,
            difference: 0
          }
        }
      );
    }

    this.createHtmlString(results);
    this.tableData = results;
  }

  createHtmlString( tableData ) {
    let html = '';
    for (const player of tableData) {
      if (player.games === 0 && this.sessions.length !== 0 && this.trainingId) {
        continue;
      }
      html += `<tr>
      <td>${(!player.player) ? '' : player.player}</td>
      <td>${player.games}</td>
      <td>${player.points}</td>
      <td>${player.legs.difference}</td>
      </tr>
      `;
    }
    this.tableHtml = this.sanitized.bypassSecurityTrustHtml(html);
  }

}
