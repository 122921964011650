<div class="login">
  <div class="container">

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <img src="./assets/img/logo_default.png" alt="Dart Plan">
      <p>Bitte gib deine E-Mail Adresse ein, mit der du dich registriert hast.</p>
      <div class="form-group">
        <img src="assets/img/mail.svg" alt="E-Mail">
        <input placeholder="E-Mail" type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Bitte gib deine E-Mail Adresse ein.</div>
          <div *ngIf="f.email.errors.email">Bitte gib eine valide E-Mail Adresse ein</div>
        </div>
      </div>
      <button [disabled]="loading" class="btn btn-primary" style="margin-top: 20px">
        <img *ngIf="loading" src="assets/img/loading.svg" alt="loading">
        Passwort zurücksetzen
      </button>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      <p class="back"><a routerLink="/">Zurück zum Login</a> </p>
    </form>
  </div>
</div>
