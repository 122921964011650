import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationType, INotification } from './_models/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  private notification$: Subject<INotification> = new BehaviorSubject(null);

  success(message: string, duration: number = null) {
    this.notify(message, NotificationType.Success, duration);
  }

  warning(message: string, duration: number = null) {
    this.notify(message, NotificationType.Warning, duration);
  }

  error(message: string, duration: number = null) {
    this.notify(message, NotificationType.Error, duration);
  }

  private notify(message: string, type: NotificationType,       duration: number) {
    duration = !duration ? 3000 : duration;
    this.notification$.next({
      message,
      type,
      duration
    } as INotification);
  }

  get notification() {
    return this.notification$.asObservable();
  }
}
