<div class="head">
  <h1>Trainingsteilnehmer</h1>
</div>
<div class="training-startup" *ngIf="currentTraining.matches.length === 0">
  <p>Welche Mannschaftsmitglieder sind beim Training anwesend?</p>
  <ul *ngIf="team.length === 0">
    <li><b>Keine Team Mitglider vorhanden. Jetzt <a routerLink="/team">Mitglieder anlegen</a> </b></li>
  </ul>
  <ul *ngIf="team.length > 0">
    <li *ngFor="let player of team">
      <label>
        <input type="checkbox" name="player" value="{{player._id}}" [(ngModel)]="player.isChecked" (change)="changeSelection()">
        {{player.firstName}} {{player.lastName}}</label>
    </li>
  </ul>
  <p><b>Gibt es Gäste?</b></p>
  <button (click)="addGuestTemplate()" class="addGuest">+ Gast hinzufügen</button>
  <div class="guests">

  </div>
  <div class="rounds">
    <label for="rounds">Wie viele Runden möchtet ihr gegeneinander spielen?</label>
    <input type="number" id="rounds" min="1" value="1"> Runde(n)
  </div>

  <div class="submit">
    <button *ngIf="!loading" class="startTraining" (click)="generateTraining()">Training starten</button>
    <svg *ngIf="loading" version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
  <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
    <animate
      attributeName="opacity"
      dur="1s"
      values="0;1;0"
      repeatCount="indefinite"
      begin="0.1"/>
  </circle>
      <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
    <animate
      attributeName="opacity"
      dur="1s"
      values="0;1;0"
      repeatCount="indefinite"
      begin="0.2"/>
  </circle>
      <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
    <animate
      attributeName="opacity"
      dur="1s"
      values="0;1;0"
      repeatCount="indefinite"
      begin="0.3"/>
  </circle>
</svg>
  </div>
</div>

<app-footer></app-footer>
