import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NotificationService} from '@app/notification.service';
import {INotification, NotificationType} from '@app/_models/notifications';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less']
})
export class NotificationComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild('notificationContainer') container: ElementRef<HTMLDivElement>;
  private subscribed = true;
  private classMap: Map<NotificationType, string>;

  constructor(
    private service: NotificationService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.classMap = new Map<NotificationType, string>();
    this.classMap.set(NotificationType.Success, 'success');
    this.classMap.set(NotificationType.Warning, 'warning');
    this.classMap.set(NotificationType.Error, 'error');

    this.service.notification
      .pipe(takeWhile(() => this.subscribed))
      .subscribe(notification => {
        if (notification) { this.render(notification); }
      });
  }

  ngOnDestroy() {
    this.subscribed = false;
  }
  private render(notification: INotification) {
    const notificationBox = this.renderer.createElement('div');
    const header = this.renderer.createElement('b');
    const content = this.renderer.createElement('div');
    const boxColorClass = this.classMap.get(notification.type);
    const classesToAdd = ['message-box', boxColorClass];
    classesToAdd.forEach(x => this.renderer.addClass(notificationBox, x));
    this.renderer.setStyle(notificationBox, 'transition', `opacity ${notification.duration}ms`);
    this.renderer.setStyle(notificationBox, 'opacity', '1');
    const headerText = this.renderer.createText(NotificationType[notification.type]);
    this.renderer.appendChild(header, headerText);
    const text = this.renderer.createText(notification.message);
    this.renderer.appendChild(content, text);
    this.renderer.appendChild(this.container.nativeElement, notificationBox);
    this.renderer.appendChild(notificationBox, header);
    this.renderer.appendChild(notificationBox, content);
    setTimeout(() => {
      this.renderer.setStyle(notificationBox, 'opacity', '0');
      setTimeout(() => {
        this.renderer.removeChild(this.container.nativeElement, notificationBox);
      }, notification.duration);
    }, notification.duration);
  }

}
