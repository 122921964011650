import { BrowserAnimationsModule } from '@angular/platform-browser/animations';import {
  trigger, state, style, animate, transition, query,
  animateChild, group
} from '@angular/animations';
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ right: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ right: '150%', opacity: 0 }))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ right: '0%' }))
        ]),
      ]),
    ]),
  ]);
