import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import {TeamComponent} from '@app/team/team.component';
import {TrainingComponent} from '@app/training/training.component';
import {StatisticComponent} from '@app/statistic/statistic.component';
import {SessionComponent} from '@app/session/session.component';
import {SettingsComponent} from '@app/settings/settings.component';
import {EditPlayerComponent} from '@app/team/edit-player/edit-player.component';
import {PasswordForgotComponent} from '@app/password-forgot/password-forgot.component';
import {PasswordTokenComponent} from '@app/password-forgot/password-token/password-token.component';
import {SuccessComponent} from '@app/password-forgot/success/success.component';
import {DsabDatesComponent} from '@app/dsab-dates/dsab-dates.component';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard], data: {animation: 'HomePage'} },
    { path: 'login', component: LoginComponent, data: {animation: 'LoginPage'} },
    { path: 'forgot-password', component: PasswordForgotComponent, data: {animation: 'PasswordForgotPage'} },
    { path: 'forgot-password/success', component: SuccessComponent, data: {animation: 'PasswordForgotPage'} },
    { path: 'forgot-password/token', component: PasswordTokenComponent, data: {animation: 'PasswordForgotTokenPage'} },
    { path: 'team', component: TeamComponent, canActivate: [AuthGuard], data: {animation: 'TeamPage'} },
    { path: 'team/dates', component: DsabDatesComponent, canActivate: [AuthGuard], data: {animation: 'TeamDatesPage'} },
    { path: 'team/edit-player/:id', component: EditPlayerComponent, canActivate: [AuthGuard], data: {animation: 'EditPlayerPage'} },
    { path: 'training', component: TrainingComponent, canActivate: [AuthGuard], data: {animation: 'TrainingPage'} },
    { path: 'training/:id', component: SessionComponent, canActivate: [AuthGuard], data: {animation: 'TrainingDetails'} },
    { path: 'statistic', component: StatisticComponent, canActivate: [AuthGuard], data: {animation: 'StatisticsPage'} },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: {animation: 'SettingsPage'} },
    // otherwise redirect to home
    { path: '**', redirectTo: '', data: {animation: 'all'}}
];

export const appRoutingModule = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
