<div class="head" *ngIf="currentTraining">
  <h1>Training vom {{getFormatedDate(currentTraining.date)}}</h1>
</div>
<div class="training-started" *ngIf="currentTraining">

  <div class="session">
    <div class="matches">
      <div class="match" *ngFor="let match of currentTraining.matches">
        <div>{{match.home.firstName}} {{match.home.lastName}}</div>
        <div class="inputs">
          <input type="number" min="0" value="{{match.home.result}}">
          <div class="align">:</div>
          <input type="number" min="0" value="{{match.guest.result}}">
        </div>
        <div>{{match.guest.firstName}} {{match.guest.lastName}}</div>
      </div>
      <!--<button (click)="saveSession()">Speichern</button> -->
    </div>
    <div class="holder">
      <div class="table">
        <h3>Trainingskosten</h3>
        <app-costs [refresh]="refresh" [trainingSession]="currentTraining"></app-costs>
      </div>
      <div class="table">
        <h3>Live-Tabelle</h3>
        <app-table [refresh]="refresh" [trainingId]="currentTraining._id" [showLegend]="false"></app-table>
      </div>
      <div class="table">
        <h3>14 Tage Tabelle</h3>
        <app-table [refresh]="refresh" [startDate]="endDate.toISOString()"></app-table>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
