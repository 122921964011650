import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
// import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { TeamComponent } from './team/team.component';
import { TrainingComponent } from './training/training.component';
import { StatisticComponent } from './statistic/statistic.component';
import { SessionComponent } from './session/session.component';
import { TableComponent } from './table/table.component';
import { CostsComponent } from './costs/costs.component';
import { SettingsComponent } from './settings/settings.component';
import { AddPlayerComponent } from './team/add-player/add-player.component';
import { NotificationComponent } from './notification/notification.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DsabTableComponent } from './dsab-table/dsab-table.component';
import { EditPlayerComponent } from './team/edit-player/edit-player.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordTokenComponent } from './password-forgot/password-token/password-token.component';
import { SuccessComponent } from './password-forgot/success/success.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {environment} from '../environments/environment';
import { DsabDatesComponent } from './dsab-dates/dsab-dates.component';
import { FooterComponent } from './footer/footer.component';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    appRoutingModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        TeamComponent ,
        TrainingComponent ,
        StatisticComponent ,
        SessionComponent ,
        TableComponent,
        CostsComponent,
        SettingsComponent ,
        AddPlayerComponent ,
        NotificationComponent,
        DsabTableComponent,
        EditPlayerComponent,
        PasswordForgotComponent,
        PasswordTokenComponent,
        SuccessComponent,
        DsabDatesComponent,
        FooterComponent  ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
