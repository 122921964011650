import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@app/_services';
import {Meta, Title} from '@angular/platform-browser';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-password-token',
  templateUrl: './password-token.component.html',
  styleUrls: ['./password-token.component.less']
})
export class PasswordTokenComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle('Passwort zurücksetzen - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Setze dein Passwort zurück'
    }, "name='description'");

    this.loginForm = this.formBuilder.group({
      token: ['', Validators.required],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const values = this.loginForm.value;
    if (values.password !== values.confirmPassword) {
      this.error = 'Deine Passwörter stimmen nicht überein.';
      return;
    }

    this.loading = true;
    this.authenticationService.resetPassword(
      this.loginForm.value.token,
      this.loginForm.value.password,
      this.loginForm.value.confirmPassword
    )
      .pipe(first())
      .subscribe(
        data => {
          // @ts-ignore
          if (data.error === true) {
            // @ts-ignore
            this.error = data.message;
            this.loading = false;
          } else {
            this.router.navigate(['/forgot-password/success']);
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
