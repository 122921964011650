<div class="head">
  <h1>Einstellungen</h1>
</div>

<div class="setting">
  <h3>Tabelle der Mannschaft integrieren</h3>
  <div class="site">
    <div class="left">
      <label>In welchem Bundesland spielt deine Mannschaft?</label>
      <select id="area" (change)="getAreaGroups($event)">
        <option selected disabled>Bitte wähle ein Bundesland</option>
        <option *ngFor="let area of areas" value="{{area}}">
          {{area}}
        </option>
      </select>

      <div class="groups" *ngIf="groups.length > 0">
        <label>In welchem Bezirk spielt deine Mannschaft?</label>
        <select (change)="getSeasons($event)" id="group">
          <option selected disabled>Bitte wähle einen Bezirk</option>
          <option *ngFor="let group of groups" value="{{group.leagueGroupId}}">
            {{group.leagueGroup}} ({{group.countLeagues}})
          </option>
        </select>
      </div>

      <div class="seasons" *ngIf="seasons.length > 0">
        <label>In welcher Saison spielt deine Mannschaft?</label>
        <select (change)="getLeagues($event)">
          <option *ngFor="let season of seasons" value="{{season.seasonId}}">
            {{season.seasonName}} ({{season.startDate}} - {{season.endDate}})
          </option>
        </select>
      </div>

      <div class="seasons" *ngIf="leagues.length > 0">
        <label>In welcher Liga spielt deine Mannschaft?</label>
        <select (change)="selectedLeague($event)">
          <option *ngFor="let league of leagues" value="{{league.leagueId}}">
            {{league.leagueName}}
          </option>
        </select>
      </div>
      <div class="team" *ngIf="teams.length > 0">
        <label>Wie heißt dein Team?</label>
        <select (change)="saveTeam($event)">
          <option>Team wählen</option>
          <option *ngFor="let team of teams"[selected]="settings.team === team" value="{{team}}">
            {{team}}
          </option>
        </select>
      </div>

      <div class="settings">
        <b>Deine Einstellungen:</b>
        <span>Bundesland:</span> <span>{{settings.area}}</span>
        <span>Bezirk:</span> <span>{{settings.groupName}}</span>
        <span>Saison:</span> <span>{{settings.seasonName}}</span>
        <span>Liga:</span> <span>{{settings.leagueName}}</span>
        <span>Team:</span> <span>{{settings.team}}</span>
      </div>
    </div>
    <div class="right" *ngIf="settings.area !== '' && settings.league !== '' && settings.season !== ''">
      <div class="table dsab">
        <div class="inner">
          <app-dsab-table [settings]="settings" [change]="refresh"></app-dsab-table>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>
