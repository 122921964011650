import { Component, OnInit } from '@angular/core';
import {UserService} from '@app/_services';
import {first} from 'rxjs/operators';
import {NotificationService} from '@app/notification.service';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.less']
})
export class TeamComponent implements OnInit {
  team = [];
  showNewPlayerArea = false;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Deine Mannschaft - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Hier kannst du deine Darts Mannschaftsmitglieder verwalten.'
    }, "name='description'");
    this.userService.getTeam().pipe(first()).subscribe(team => {
      this.team = team;
    });
  }

  removePlayer( member ) {
    if (confirm(`Möchtest du ${member.firstName} ${member.lastName} wirklich endgültig löschen?`)) {
      member.dsabNumber = member.dsabNumber.toString();
      this.userService.deleteTeamMember(member)
        .pipe(first()).subscribe( team => {
          this.notificationService.success('Mitglied erfolgreich entfernt.');
          this.ngOnInit();
      }, error => {
          this.notificationService.error('Fehler beim entfernen vom Mitglied. ' + error);
      });
    } else {
      this.notificationService.error('Mitglied wurde nicht gelöscht.');
    }
  }

  editPlayer( member ) {
    this.router.navigate(['/team/edit-player/' + member._id]);
  }

  playerAdded() {
    this.notificationService.success('Spieler hinzugefügt.', 5000);
    this.showNewPlayerArea = false;
    this.ngOnInit();
  }

}
