import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {UserService} from '@app/_services';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, first} from 'rxjs/operators';
import {Training} from '@app/_models/training';
import {Meta, Title} from '@angular/platform-browser';
import {NotificationService} from '@app/notification.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.less']
})
export class SessionComponent implements OnInit {
  currentTraining: Training;
  // tslint:disable-next-line:ban-types
  listenFunc: Function;
  endDate = new Date(new Date().setDate(new Date().getDate() - 14));
  refresh = '1233';
  timer = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    elementRef: ElementRef,
    renderer: Renderer2,
    private titleService: Title,
    private metaService: Meta,
    private notification: NotificationService
  ) {
    this.listenFunc = renderer.listen(
      elementRef.nativeElement, 'change', (event) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveSession();
        }, 3000);
      }
    );
  }

  getFormatedDate( date ) {
    const dateItem = new Date(date);
    return dateItem.getDate() + '.' + dateItem.getMonth() + '.' + dateItem.getFullYear();
  }

  ngOnInit() {
    this.titleService.setTitle('Trainings Details - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Details zu deiner Trainingssession.'
    }, "name='description'");
    const key = 'id';
    this.userService.getSession(this.route.snapshot.params[key])
      .pipe(first())
      .subscribe(session => {
        this.currentTraining = session;
      },
        error => {
          this.router.navigate(['/training']);
      });
  }

  saveSession() {
    const matches = document.getElementsByClassName('match');
    for (const match of Object.keys(matches)) {
      const inputs = matches[match].getElementsByTagName('input');
      this.currentTraining.matches[match].home.result = inputs[0].value;
      this.currentTraining.matches[match].guest.result = inputs[1].value;
    }
    this.userService.updateSession(this.currentTraining._id, this.currentTraining)
      .pipe(first())
      .subscribe(session => {
        this.refresh = btoa(Math.random().toString()).substring(10, 15);
        this.notification.success('Training gespeichert.')
      }, error => {
        this.notification.error('Fehler beim speichern des Trainings.')
      });
  }

}
