import {Component, OnInit} from '@angular/core';
import {RanglisteService} from '@app/_services/rangliste.service';
import {UserService} from '@app/_services';
import {Meta, Title} from '@angular/platform-browser';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-dsab-dates',
  templateUrl: './dsab-dates.component.html',
  styleUrls: ['./dsab-dates.component.less']
})
export class DsabDatesComponent implements OnInit {
  seasonId;
  leagueId;
  league;
  dates;
  teams = [];
  currentFilter;
  message;

  constructor(
    private api: RanglisteService,
    private userService: UserService,
    private titleService: Title,
    private metaService: Meta
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Team Termine - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Dein Mannschafts-Termine.'
    }, "name='description'");

    // @ts-ignore
    if (window.hasOwnProperty('settings') && window.settings.league !== "" && window.settings.season !== "") {
      // @ts-ignore
      this.seasonId = window.settings.season;
      // @ts-ignore
      this.leagueId = window.settings.league;
      // @ts-ignore
      this.currentFilter = window.settings.team;
      this.getLeague();
    } else {
      this.userService.getSettings().pipe(first()).subscribe(settings => {
        if (settings) {
          if (Array.isArray(settings) && settings.length > 0)
            // @ts-ignore
            window.settings = settings[0];
            this.ngOnInit();
        } else {
          this.message = 'Keine Liga Einstellungen gefunden. Gehe zu den Einstellungen und hinterlege deine Liga Informationen';
        }
      })
    }
  }

  getLeague() {
    if (!this.seasonId || !this.leagueId) return;
    this.api.getLeague(this.seasonId, this.leagueId).pipe(first()).subscribe(league => {
      if (league.length > 0) {
        this.league = league[0];
        this.getTeams();
        if (this.currentFilter) {
          this.filterDates()
        } else {
          this.dates = this.league.dates;
        }
      }
    }, error => {
      console.log(error)
    })
  }

  getTeams() {
    this.teams = [];
    for (const game of this.league.dates) {
      if (!this.teams.includes(game.homeTeam)) {
        this.teams.push(game.homeTeam);
      }
      if (!this.teams.includes(game.guestTeam)) {
        this.teams.push(game.guestTeam);
      }
    }
  }

  filterDates() {
    if (!this.currentFilter || this.currentFilter === "") {
      return;
    }
    this.dates = this.league.dates.filter(date => date.homeTeam === this.currentFilter || date.guestTeam === this.currentFilter);
  }

}
