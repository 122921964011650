import {Component, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {UserService} from '@app/_services';
import {NotificationService} from '@app/notification.service';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-player',
  templateUrl: './edit-player.component.html',
  styleUrls: ['./edit-player.component.less']
})
export class EditPlayerComponent implements OnInit {
  team = [];
  player_id;
  player_index;
  form;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Mitglied bearbeiten - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Hier kannst du dein Mitglied bearbeiten.'
    }, "name='description'");
    const key = 'id';
    this.player_id = this.route.snapshot.params[key];

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      street: ['', [Validators.required, Validators.minLength(3)]],
      plz: ['', [Validators.required, Validators.pattern('[0-9]{5}')]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      dsabNumber: ['', [Validators.required, Validators.minLength(3)]],
      birthDate: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(3)]],
    });

    this.userService.getTeam().pipe(first()).subscribe(team => {
      this.team = team;
      this.player_index = this.team.findIndex((player) => player._id === this.player_id);
      this.firstName.setValue(team[this.player_index].firstName)
      this.lastName.setValue(team[this.player_index].lastName)
      this.street.setValue(team[this.player_index].street)
      this.plz.setValue(team[this.player_index].plz)
      this.city.setValue(team[this.player_index].city)
      this.dsabNumber.setValue(team[this.player_index].dsabNumber)
      this.birthDate.setValue(team[this.player_index].birthDate)
      this.phone.setValue(team[this.player_index].phone)
    });
  }

  onSubmit() {
    this.team[this.player_index].firstName = this.form.value.firstName;
    this.team[this.player_index].lastName = this.form.value.lastName;
    this.team[this.player_index].street = this.form.value.street;
    this.team[this.player_index].plz = this.form.value.plz;
    this.team[this.player_index].city = this.form.value.city;
    this.team[this.player_index].dsabNumber = this.form.value.dsabNumber;
    this.team[this.player_index].birthDate = this.form.value.birthDate;
    this.team[this.player_index].phone = this.form.value.phone;


    this.userService.updateTeam(this.team)
      .pipe(first())
      .subscribe(() => {
        this.notificationService.success('Mitglied erfolgreich aktualisiert.')
        this.goBack();
      }, error => {
        this.notificationService.error('Das Mitglied konnte nicht aktualisiert werden.')
      })
  }

  goBack() {
    this.router.navigate(['/team']);
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get street() {
    return this.form.get('street');
  }

  get plz() {
    return this.form.get('plz');
  }

  get city() {
    return this.form.get('city');
  }

  get dsabNumber() {
    return this.form.get('dsabNumber');
  }

  get birthDate() {
    return this.form.get('birthDate');
  }

  get phone() {
    return this.form.get('phone');
  }

}
