<div class="outer">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form">
      <div>
        <label for="firstName">Vorname</label>
        <input type="text" id="firstName" name="firstName" formControlName="firstName">
        <div class="errors" *ngIf="!firstName?.valid && (firstName?.dirty ||firstName?.touched)">
          <div [hidden]="!firstName.errors.required">
            Vorname ist ein Pflichtfeld
          </div>
          <div [hidden]="!firstName.errors.minlength">
            Bitte gib mindestens 3 Buchstaben ein.
          </div>
        </div>
      </div>
      <div>
        <label for="lastName">Nachname</label>
        <input type="text" id="lastName" name="lastName" formControlName="lastName">
        <div class="errors" *ngIf="!lastName?.valid && (lastName?.dirty ||lastName?.touched)">
          <div [hidden]="!lastName.errors.required">
            Nachname ist ein Pflichtfeld
          </div>
          <div [hidden]="!lastName.errors.minlength">
            Bitte gib mindestens 3 Buchstaben ein.
          </div>
        </div>
      </div>

      <div>
        <label for="street">Straße</label>
        <input type="text" id="street" name="street" formControlName="street">
        <div class="errors" *ngIf="!street?.valid && (street?.dirty ||street?.touched)">
          <div [hidden]="!street.errors.required">
            Straße ist ein Pflichtfeld
          </div>
          <div [hidden]="!street.errors.minlength">
            Bitte gib mindestens 3 Buchstaben ein.
          </div>
        </div>
      </div>
      <div>
        <label for="plz">Postleitzahl</label>
        <input type="text" id="plz" name="plz" formControlName="plz">
        <div class="errors" *ngIf="!plz?.valid && (plz?.dirty || plz?.touched)">
          <div [hidden]="!plz.errors.required">
            Postleitzahl ist ein Pflichtfeld
          </div>
          <div [hidden]="!plz.errors.minlength">
            Bitte gib mindestens 5 Zahlen ein.
          </div>
        </div>
      </div>
      <div>
        <label for="city">Stadt</label>
        <input type="text" id="city" name="city" formControlName="city">
        <div class="errors" *ngIf="!city?.valid && (city?.dirty ||city?.touched)">
          <div [hidden]="!city.errors.required">
            Stadt ist ein Pflichtfeld
          </div>
          <div [hidden]="!city.errors.minlength">
            Bitte gib mindestens 3 Buchstaben ein.
          </div>
        </div>
      </div>

      <div>
        <label for="phone">Telefon</label>
        <input type="text" id="phone" name="phone" formControlName="phone">
        <div class="errors" *ngIf="!phone?.valid && (phone?.dirty ||phone?.touched)">
          <div [hidden]="!phone.errors.required">
            Telefon ist ein Pflichtfeld
          </div>
          <div [hidden]="!phone.errors.minlength">
            Bitte gib mindestens 3 Buchstaben ein.
          </div>
        </div>
      </div>
      <div>
        <label for="birthDate">Geburtsdatum</label>
        <input type="date" id="birthDate" name="birthDate" formControlName="birthDate">
        <div class="errors" *ngIf="!birthDate?.valid && (birthDate?.dirty ||birthDate?.touched)">
          <div [hidden]="!birthDate.errors.required">
            Geburtsdatum ist ein Pflichtfeld
          </div>
        </div>
      </div>
      <div>
        <label for="dsabNumber">DSAB Nummer</label>
        <input type="text" id="dsabNumber" name="dsabNumber" formControlName="dsabNumber">
        <div class="errors" *ngIf="!dsabNumber?.valid && (dsabNumber?.dirty ||dsabNumber?.touched)">
          <div [hidden]="!dsabNumber.errors.required">
            DSAB Nummer ist ein Pflichtfeld
          </div>
          <div [hidden]="!dsabNumber.errors.minlength">
            Bitte gib mindestens 3 Buchstaben ein.
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button type="button" class="delete" (click)="abortNewPlayer()">Abbrechen</button>
      <button type="submit" [disabled]="!form.valid">Speichern</button>
    </div>
  </form>

</div>
