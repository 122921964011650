import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AuthenticationService, UserService} from '@app/_services';
import {first} from 'rxjs/operators';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {state} from '@angular/animations';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.less']
})
export class TrainingComponent implements OnInit {
  team = [];
  trainingPlayers = [];
  checkedIDs = [];
  loading = false;
  currentTraining = {
    user_id: '',
    date: new Date(),
    matches: []
  };
  // tslint:disable-next-line:ban-types
  listenFunc: Function;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    elementRef: ElementRef,
    renderer: Renderer2,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.listenFunc = renderer.listen(
      elementRef.nativeElement, 'click', (event) => {
        if (event.target.classList.contains('removeItem')) {
          event.target.parentElement.remove();
        }
      }
    );
  }

  ngOnInit() {
    this.titleService.setTitle('Training starten - Dart Plan');
    this.metaService.updateTag( {
      name: 'description',
      content: 'Erstelle eine neue Trainings-Session für deine Mannschaft.'
    }, "name='description'");
    this.userService.getTeam().pipe(first()).subscribe(team => {
      for (const player of team) {
        this.team.push({
          firstName: player.firstName,
          lastName: player.lastName,
          isChecked: true,
          id: player._id,
        });
      }
      this.fetchSelectedItems();
      this.fetchCheckedIDs();
    });
  }

  addGuestTemplate() {
    const row = document.createElement('div');
    row.className = 'row';
    row.innerHTML = `
    <input type="text" placeholder="Gast Name" class="guest">
    <input type="text" placeholder="Gast Nachname" class="guest_lastName">
    <button class="removeItem" (click)="removeParent">X</button>
    `;
    document.querySelector('.guests').appendChild(row);
  }

  getGuests() {
    const guests = document.getElementsByClassName('guest');
    for (const guest of Object.keys(guests)) {
      const lastNameInput = guests[guest].parentElement.getElementsByClassName('guest_lastName')[0];

      const player = {
        id: (Math.random() * (50000000 - 2000) + 2000).toString(),
        lastName: '',
        firstName: '',
        isChecked: true
      };
      player.firstName = guests[guest].value;
      player.lastName = lastNameInput.value;
      this.trainingPlayers.push(player);
    }
  }

  changeSelection() {
    this.fetchSelectedItems();
  }

  fetchSelectedItems() {
    this.trainingPlayers = this.team.filter((value, index) => {
      return value.isChecked;
    });
  }

  fetchCheckedIDs() {
    this.checkedIDs = [];
    this.team.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
  }

  async generateTraining() {
    if (this.loading) { return; }

    this.loading = true;
    this.getGuests();
    const rounds = document.getElementById('rounds') as HTMLInputElement;
    const matches = [];
    let i = 0;
    while (i < Number(rounds.value)) {
      matches.push(...this.choose(this.trainingPlayers, 2));
      i++;
    }
    for ( const match of matches ) {
      this.currentTraining.matches.push({
        home: {
          firstName: match[0].firstName,
          lastName: match[0].lastName,
          id: match[0].id,
          result: 0
        },
        guest: {
          firstName: match[1].firstName,
          lastName: match[1].lastName,
          id: match[1].id,
          result: 0
        }
      });
    }

    const currentUser = this.authenticationService.currentUserValue;
    this.currentTraining.user_id = currentUser.id.toString();

    this.userService.saveSession(this.currentTraining)
      .pipe(first())
      .subscribe(training => {
        this.loading = false;
        if (training.hasOwnProperty('_id')) {
          // @ts-ignore
          this.router.navigate(['/training/' + training._id]);
        }
      });

  }

  choose(arr, k, prefix= []) {
    if (k === 0) { return [prefix]; }
    return arr.flatMap((v, i) =>
      this.choose(arr.slice(i + 1), k - 1, [...prefix, v])
    );
  }
}
