import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {first} from 'rxjs/operators';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.less']
})
export class CostsComponent implements OnInit, OnChanges {
  @Input() trainingSession;
  @Input() refresh: string;
  player = [];
  tableHtml: SafeHtml;

  constructor(
    private sanitized: DomSanitizer
  ) { }

  ngOnInit() {
    this.getPlayer();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getPlayer();
  }

  getCosts() {
    // tslint:disable-next-line:forin
    for (const player in this.player) {
      if (this.player.hasOwnProperty(player)) {
        const matches = this.trainingSession.matches.filter(obj => {
          return obj.home.id === this.player[player].id || obj.guest.id === this.player[player].id;
        });

        for (const match of matches) {
          this.player[player].legs = this.player[player].legs + Number(match.home.result) + Number(match.guest.result);
        }
      }
      this.player[player].cost = this.player[player].legs * 0.5;
    }
    this.createHtmlString();
  }

  getPlayer() {
    this.player = [];
    for (const match of this.trainingSession.matches) {
      if (this.player.filter(e => e.id === match.home.id).length === 0) {
        this.player.push({
          firstName: match.home.firstName,
          lastName: match.home.lastName,
          id: match.home.id,
          legs: 0,
          cost: 0
        });
      }
      if (this.player.filter(e => e.firstName === match.guest.firstName).length === 0) {
        this.player.push({
          firstName: match.guest.firstName,
          lastName: match.guest.lastName,
          id: match.guest.id,
          legs: 0,
          cost: 0
        });
      }
    }
    this.getCosts();
  }

  createHtmlString() {
    let html = '';

    this.player.sort( (a, b) => {
      if (a.legs > b.legs) {
        return -1;
      }
      if (a.legs < b.legs) {
        return 1;
      }
      return 0;
    });

    const total = {
      firstName: 'Total',
      lastName: '',
      id: 'blub',
      legs: 0,
      cost: 0
    };
    for (const match of this.trainingSession.matches) {
      total.legs = total.legs + Number(match.home.result) + Number(match.guest.result);
    }

    // @ts-ignore
    const games = Number(document.getElementById('count').value);
    // @ts-ignore
    const price = Number(document.getElementById('price').value);

    const multiplier = (total.legs / games).toFixed(0);
    const subtotal = ( Number(multiplier) * price) + ( (total.legs - (Number(multiplier) * games)) * 0.5 );
    total.cost = subtotal * 2;
    document.getElementById('total').innerText = total.cost.toFixed(2) + ' €';

    for (const player of this.player) {
      html += `<tr>
        <td>${player.firstName} ${player.lastName}</td>
        <td>${player.legs}</td>
        <td>${player.cost.toFixed(2)} €</td>
        </tr>
      `;
      this.tableHtml = this.sanitized.bypassSecurityTrustHtml(html);
    }
  }

}
