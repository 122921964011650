import { Component } from '@angular/core';
import {ChildrenOutletContexts, Router} from '@angular/router';

import {AuthenticationService, UserService} from './_services';
import { User } from './_models';
import {slideInAnimation} from '@app/animation';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  animations: [slideInAnimation]
})
export class AppComponent {
    currentUser: User;
    openMenu: boolean;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private contexts: ChildrenOutletContexts
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        if (this.currentUser) {
          document.body.classList.add('logged-in');
        }
    }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

    logout() {
        this.openMenu = false;
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
