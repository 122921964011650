import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import {Training} from '@app/_models/training';
import {NotificationService} from '@app/notification.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {
    refresh = '1233';
    sessions: Training[];
    minusDate = new Date(new Date().setDate(new Date().getDate() - 14));
    firstOfYear = new Date(new Date().getFullYear(), 0, 1);
    lastOfYear = new Date(new Date().getFullYear(), 12, 31);
    startCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    endCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    customStartDate: Date = new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));
    customEndDate: Date = new Date(new Date().setHours(23, 59, 59));
    settings;
    // tslint:disable-next-line:ban-types
    listenFunc: Function;
    currentPage;
    totalPages;

    constructor(
      private userService: UserService,
      private auth: AuthenticationService,
      elementRef: ElementRef,
      renderer: Renderer2,
      private notificationService: NotificationService,
      private titleService: Title,
      private metaService: Meta
    ) {
      this.listenFunc = renderer.listen(
        elementRef.nativeElement, 'change', (event) => {
          // @ts-ignore
          const end = new Date(document.getElementById('toDate').value);
          end.setHours(24, 59, 59);

          // @ts-ignore
          const start = new Date(document.getElementById('fromDate').value);
          start.setHours(0, 0, 0);

          if (isFinite(+end) && isFinite(+start)) {
            this.customEndDate = end;
            this.customStartDate = start;
            this.refresh = btoa(Math.random().toString()).substring(10, 15);
          }
        }
      );
    }

    ngOnInit() {
      this.titleService.setTitle('Dashboard - Dart Plan');
      this.metaService.updateTag( {
        name: 'description',
        content: 'Dein Mannschafts Dashboard.'
      }, "name='description'");

      this.userService.getAllSessions().pipe(first()).subscribe(sessions  => {
        this.sessions = sessions.session;
        this.currentPage = Number(sessions.currentPage);
        this.totalPages = Number(sessions.totalPages);

        this.userService.getSettings().pipe(first()).subscribe(settings => {
          if (settings) {
            if (Array.isArray(settings) && settings.length > 0)
              // @ts-ignore
              window.settings = settings[0];
              // @ts-ignore
              if (window.hasOwnProperty('settings')) {
                // @ts-ignore
                this.settings = window.settings;
              }
          }
        })
      });
    }

    loadMore() {
      if (this.currentPage === this.totalPages) {
        return;
      }
      this.userService.getAllSessions((this.currentPage + 1)).pipe(first()).subscribe(sessions => {
        this.currentPage = Number(sessions.currentPage);
        this.totalPages = Number(sessions.totalPages);
        this.sessions.push(...sessions.session);
      })
    }

    getFormatedDate( date ) {
      const dateItem = new Date(date);
      return dateItem.getDate() + '.' + dateItem.getMonth() + '.' + dateItem.getFullYear();
    }

    getPlayer( matches ) {
      const player = [];
      for (const match of matches ) {
        if (!player.includes(match.home.firstName)) {
          player.push(match.home.firstName);
        }
        if (!player.includes(match.guest.firstName)) {
          player.push(match.guest.firstName);
        }
      }

      return player.join(', ');
    }

    deleteSession( sessionId ) {
      if (confirm('Möchtest du diese Trainings Session wirklich endgültig löschen?')) {
        this.userService.deleteSession(sessionId).pipe(first()).subscribe(sessions => {
          this.sessions = sessions;
          this.notificationService.success('Training erfolgreich entfernt.');
        });
      }
    }
}
