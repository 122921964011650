<div class="loading" *ngIf="!tableHtml">
  Lade Kosten<br>
  <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
  <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
    <animate
      attributeName="opacity"
      dur="1s"
      values="0;1;0"
      repeatCount="indefinite"
      begin="0.1"/>
  </circle>
    <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
    <animate
      attributeName="opacity"
      dur="1s"
      values="0;1;0"
      repeatCount="indefinite"
      begin="0.2"/>
  </circle>
    <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
    <animate
      attributeName="opacity"
      dur="1s"
      values="0;1;0"
      repeatCount="indefinite"
      begin="0.3"/>
  </circle>
</svg>
</div>

<div class="tableComponent">
  <table>
    <thead>
    <tr>
      <th>Spieler</th>
      <th>Legs</th>
      <th>Kosten</th>
    </tr>
    </thead>
    <tbody [innerHTML]="tableHtml" *ngIf="tableHtml">

    </tbody>
  </table>
  <div class="settings">
    <div>
      Anzahl Spiele <input type="number" id="count" value="5">
    </div>
    <div>
      Kosten <input type="number" id="price" value="2">
    </div>
    <div>
      Gesamtkosten <span id="total"></span>
    </div>
  </div>
</div>
