import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import {TeamMember} from '@app/_models/teamMember';
import {Training, TrainingPagination} from '@app/_models/training';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getTeam() {
      return this.http.get<TeamMember[]>(`${environment.apiUrl}/team`);
    }

    updateTeam(team) {
      return this.http.post<TeamMember[]>(`${environment.apiUrl}/team/update`, team);
    }

    deleteTeamMember( member ) {
      return this.http.post(`${environment.apiUrl}/team/delete`, member);
    }

    saveNewTeamMember(member) {
      return this.http.post(`${environment.apiUrl}/team`, member);
    }

    saveSession( training ) {
      return this.http.post(`${environment.apiUrl}/training`, training);
    }

    getSession( id ) {
      return this.http.get<Training>(`${environment.apiUrl}/training/?id=${id}`);
    }

    getAllSessions(page = 1, limit = 10) {
      return this.http.get<TrainingPagination>(`${environment.apiUrl}/training/all?page=${page}&limit=${limit}`);
    }

    getSessionsByDate( startDate, endDate) {
      return this.http.get<Training[]>(`${environment.apiUrl}/training/byDate/?startDate=${startDate}&endDate=${endDate}`);
    }

    updateSession( id, training ) {
      return this.http.post(`${environment.apiUrl}/training/update?id=${id}`, training);
    }

    deleteSession( id ) {
      return this.http.delete<Training[]>(`${environment.apiUrl}/training?id=${id}`);
    }

    getSettings() {
      return this.http.get<any>(`${environment.apiUrl}/settings`);
    }

    saveSettings( settings ) {
      return this.http.post(`${environment.apiUrl}/settings`, settings);
    }
}
