<div class="head">
  <h1>Trainingsübersicht</h1>
</div>
<div class="grid">
  <div class="table">
    <h3>Letzten 14 Tage</h3>
    <app-table [refresh]="refresh" [startDate]="minusDate.toISOString()" [showLegend]="false"></app-table>
  </div>
  <div class="table"  *ngIf="!settings || settings.season === '' || settings.league === ''">
    <h3>Dieses Jahr</h3>
    <app-table
      [refresh]="refresh"
      [startDate]="firstOfYear.toISOString()"
      [endDate]="lastOfYear.toISOString()"
      [showLegend]="false"></app-table>
  </div>
  <div class="table" *ngIf="!settings || settings.season === '' || settings.league === ''">
    <h3>Diesen Monat</h3>
    <app-table
      [refresh]="refresh"
      [startDate]="startCurrentMonth.toISOString()"
      [endDate]="endCurrentMonth.toISOString()"
      [showLegend]="false"></app-table>
  </div>
  <div class="table">
    <div class="timeChooser">
      <div>Von</div>
      <input type="datetime-local" id="fromDate" value="{{customStartDate.toISOString().slice(0,16)}}">
      <div>Bis</div>
      <input type="datetime-local" id="toDate" value="{{customEndDate.toISOString().slice(0,16)}}">
    </div>
    <app-table
      *ngIf="customStartDate"
      [refresh]="refresh"
      [startDate]="customStartDate.toISOString()"
      [endDate]="customEndDate.toISOString()"
      [showLegend]="false"></app-table>
  </div>
  <div class="table dsab" *ngIf="settings && settings.season !== '' && settings.league !== ''">
    <h3>DSAB Tabelle</h3>
    <div class="inner">
      <app-dsab-table [settings]="settings"></app-dsab-table>
    </div>
  </div>

  <div class="sessions">
    <div class="head">
      <h1>Vergangene Trainingssessions</h1>
    </div>
    <div class="session" *ngFor="let session of sessions">
      <div class="description">
        <h3>Training vom {{getFormatedDate(session.date)}}</h3>
        <b>Datum</b><span>{{getFormatedDate(session.date)}}</span>
        <b>Teilnehmer</b><span>{{getPlayer(session.matches)}}</span>
        <div class="links">
          <a class="nav-item nav-link" routerLink="/training/{{session._id}}">Training öffnen</a>
          <a class="nav-item nav-link delete" (click)="deleteSession(session._id)">Training löschen</a>
        </div>
      </div>
      <div class="table">
        <app-table [trainingId]="session._id" [showLegend]="false"></app-table>
      </div>
    </div>
    <div class="loadMore" *ngIf="currentPage < totalPages">
      <button (click)="loadMore()">Mehr laden <img src="assets/img/load-more.svg"> </button>
    </div>
    <div class="noData" *ngIf="!sessions || sessions.length === 0">
      Keine Daten verfügbar
    </div>
  </div>
</div>

<app-footer></app-footer>
