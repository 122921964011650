<div class="team">
  <div class="head">
    <h1>Deine Teammitglieder</h1>
  </div>
  <div class="controls">
    <button (click)="showNewPlayerArea = true">Teammitglied hinzufügen</button>
  </div>
  <div class="newPlayerArea" *ngIf="showNewPlayerArea">
    <app-add-player
      (newItemEvent)="showNewPlayerArea = false;"
      (addedUser)="playerAdded()"
    ></app-add-player>
  </div>
  <div class="member">
    <ul *ngIf="team.length > 0">
      <li *ngFor="let member of team">
        <h1>{{member.firstName}} {{member.lastName}}</h1>
        <div class="details">
          <div>
            <h3>Person</h3>
            <p>
              Vorname: {{member.firstName}}<br>
              Nachname: {{member.lastName}}<br>
              Geburtstag: {{member.birthDate}}
            </p>
          </div>
          <div>
            <h3>Adresse</h3>
            <p>
              Straße: {{member.street}}<br>
              PLZ: {{member.plz}}<br>
              Ort: {{member.city}}
            </p>
          </div>
          <div>
            <h3>Kontakt</h3>
            <p>
              Tel.: {{member.phone}}<br>
              DSAB Nr.: {{member.dsabNumber}}
            </p>
          </div>
        </div>
        <button (click)="editPlayer(member)" class="edit" >Bearbeiten</button>
        <button (click)="removePlayer(member)">Entfernen</button>
      </li>
    </ul>

    <div class="noData" *ngIf="team.length === 0">
      Noch keine Teammitglieder vorhanden
    </div>
  </div>
</div>

<app-footer></app-footer>
